.wrap {
    position: relative;
    overflow: hidden;
}

.body {
    position: relative;
    padding-top: 100px;
    width: 100%;
    height: 100vh;
    min-height: 900px;

    @media (max-width: 1280px) {
        height: auto;
        min-height: unset;
    }
}

.content {
    position: relative;
    z-index: 6;

    .title {
        text-align: center;

        @media (max-width: 480px) {
            text-align: left;

            br {
                display: none;
            }
        }
    }

    .text {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 40px;
        border-left: 2px solid #D14507;
        padding-left: 18px;
        max-width: 460px;
        margin: 40px 0 0 auto;

        @media (max-width: 800px) {
            margin-left: 0;
            max-width: 100%;
        }

        @media (max-width: 480px) {
            padding-left: 12px;
            margin-top: 30px;
        }
    }

    .buttonWrap {
        position: absolute;
        left: 0;
        bottom: -45px;
        border-radius: 100%;
        background: #F6F6F8;
        width: 240px;
        height: 240px;

        @media (max-width: 1280px) {
            width: 160px;
            height: 160px;
        }

        @media (max-width: 800px) {
            display: none;
        }

        .button {
            cursor: pointer;
            border: 10px solid #fff;
            border-radius: 100%;
            background: #D14507;
            width: 110px;
            height: 110px;

            @media (max-width: 1280px) {
                width: 80px;
                height: 80px;
                border-width: 6px;
            }

            &:hover {
                .arrow {
                    transform: scale(1.2);
                }
            }

            .arrow {
                width: 28px;
                height: 28px;
                transition: transform .3s;
            }
        }
    }
}

.discoverBtn {
    position: absolute;
    z-index: 6;
    bottom: 0;
    right: 0;
    background: #fff;
    border-top: 10px solid #fff;
    border-left: 10px solid #fff;
    border-top-left-radius: 2px;

    @media (max-width: 500px) {
        position: relative;
        border: none;
        background: unset;
        border-radius: none;
        margin-top: 50px;
    }
}

.swiperWrap {
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 600px;
    pointer-events: none;

    @media (max-width: 1280px) {
        position: relative;
        height: auto;
        aspect-ratio: 1280 / 691;
        margin-top: -40px;
    }

    @media (max-width: 1280px) {
        margin-top: 0;
    }

    @media (max-width: 800px) {
        margin-top: 10px;
    }

    @media (max-width: 500px) {
        width: calc(100% + 40px);
        margin-left: -20px;
    }

    .swiper {
        width: 100%;
        height: 100%;
        overflow: visible;

        .slide {
            width: 100%;
            opacity: 0;
            transition: all 2s;
            transform: translateY(200px);

            @media (max-width: 600px) {
                transform: translateY(80px);
            }

            &.active {
                opacity: 1;
                transform: translateY(0);
            }

            .slideContent {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: flex-end;

                .image {
                    width: 100%;
                }
            }
        }
    }
}

.wave {
    position: absolute;
    z-index: 4;
    width: 100%;
    bottom: 0;
    left: 0;
    aspect-ratio: 1280/371;

    @media (max-width: 500px) {
        width: calc(100% + 40px);
        left: -20px;
    }

    &.inactive {
        bottom: -380px;
        transition: all 0s;

        @media (max-width: 480px) {
            bottom: -100px;
        }
    }

    &.active {
        bottom: 100%;
        transition: all 3s;

        @media (max-width: 480px) {
            bottom: 80%;
        }
    }

    .top {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
    }

    .bottom {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
    }
}