.home-banner {

    .swiper-pagination {
        display: flex;
        gap: 10px;
        left: 25px !important;
        bottom: 25px;
        top: unset !important;
        right: unset !important;
        transform: none !important;
        height: 19px;
        align-items: center;

        @media (max-width: 700px) {
            height: 12px;
            left: 20px !important;
            bottom: 20px;
        }

        @media (max-width: 500px) {
            bottom: -20px;
            left: 0 !important;
            width: 100%;
            justify-content: center;
        }
    }

    .swiper-pagination-bullet {
        height: 19px;
        width: 19px;
        opacity: 1;
        background: rgba(255, 255, 255, 0.3);
        transition: all .2s;

        @media (max-width: 700px) {
            width: 12px;
            height: 12px;
        }

        @media (max-width: 500px) {
            background: #EBEBEB;
        }
    }

    .swiper-pagination-bullet-active {
        background: #D14507;
    }
}